.progressBar {
  --bar-bg: #1f224c;
  --seek-before-width: 0;
  --seek-before-color: #1f224c;
  --knobby: #ff6b40;
  --selected-knobby: linear-gradient(79.65deg, #ff6b40 -23.68%, #ff8e75 109.32%), #1f224c;
  appearance: none;
  background: var(--bar-bg);
  border-radius: 1px;
  position: relative;
  width: 100%;
  height: 2px;
  outline: none;
}

/* progressBar safari */

.progressbar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 1px;
  position: relative;
  width: 100%;
  height: 2px;
  outline: none;
}

/* progressBar firefox */

.progressBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 1px;
  position: relative;
  width: 100%;
  height: 2px;
  outline: none;
}

.progressBar::-moz-focus-outer {
  border: 0;
}

/* progressBar - chrome and safari */

.progressBar::before {
  content: '';
  height: 2px;
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

/* progressBar - firefox */

.progressBar::-moz-range-progress {
  background: var(--seek-before-color);
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  height: 2px;
}

/* knobby - chrome and safari */

.progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: 0 0 0 0 -1px;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - chrome and safari */

.progressBar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--selected-knobby);
}

/* knobby firefox */

.progressBar::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - firefox */

.progressBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selected-knobby);
}
